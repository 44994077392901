.App {
  /* text-align: center; */
  /* background-color: green; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.logo {
  width: 24px;
  height: 24px;
}

.card-header {
  font-size: 2em;
}

.card-background {
  background-size: 1.5em 1.5em;
  background-image:
    linear-gradient(to right, rgba(128, 128, 128, 0.267) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(128, 128, 128, 0.267) 1px, transparent 1px);
}

a {
  text-decoration: none;
  font: inherit;
}

a:link {
  color: inherit
}

.subtitle {
  font-weight: 300;
}

.b1 {
  font-weight: 400;
}

.main-box {
  background-color: white;
}

.dark {
  background-color: black;
  color: white;
}

.light {
  background-color: white;
  color: black;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}